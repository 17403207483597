import React, { useEffect, useState } from 'react';
import FieldsList from './fields-list';
import FieldsManager from './fields-manager';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../../reducers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { saveDuplicateCheckerAction, publishDuplicateCheckerAction, saveDuplicateCheckerErrorAction } from './action';
import { IDuplicateCheckerData, IDuplicateChecker } from './interface/duplicate-checker';
import { IOption } from '../../../../../../../../common/interface/option';
import { Dimmer, Loader } from 'semantic-ui-react-bpm';
import ActionMessage from '../../../../../../../../common/general/action-message';
import { duplicateCheckerActionMessage } from './constant';
import { IHasPageChangeRef } from '../../../../../../../main/interface/has-page-change';
import changeRoute from '../../../../../../../../common/utils/change-menu';
import ButtonContainer from '../../../../button-container';
import { useGetDuplicateCheckerData } from './hooks/use-get-duplicate-checker-data';
import { useSetDuplicateCheckerState } from './hooks/use-set-duplicate-checker-state';
import { IFormStatusWithAction } from '../../../workflow/interface/form-status';

interface IDuplicateCheckerContainerProps {
  hasPageChangeRef: React.MutableRefObject<IHasPageChangeRef>;
}

const DuplicateCheckerContainer: React.FC<IDuplicateCheckerContainerProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match: { params: { formId: string } } = useRouteMatch();

  const { hasPageChange } = useSelector((state: IRootState) => state.main);
  const { company } = useSelector((state: IRootState) => state.auth);
  const {
    formFieldsOptionList,
    duplicateChecker,
    duplicateCheckerSaveType,
    duplicateCheckerLoading,
    saveDuplicateCheckerLoading,
    formFieldsLoading
  } = useSelector((state: IRootState) => state.duplicateChecker);
  const {
    formStatusList
  } = useSelector((state: IRootState) => state.workflow);

  const [formFieldOption, setFormFieldOption] = useState([] as IOption[]);

  const {
    duplicateCheckerData,
    setDuplicateCheckerData
  } = useSetDuplicateCheckerState({ duplicateChecker });

  const statusOptionList = (): IOption[] => {
    if (formStatusList && formStatusList.length > 0) {
      return formStatusList.map((status: IFormStatusWithAction, key: number) => {
        return {
          key: key,
          text: status.name,
          value: status.id
        }
      });
    }
    return [];
  }

  const onFormFieldOptionChange = (values: string[], type: 'status' | 'fields') => {
    let formDuplicateCheckerData = { ...duplicateCheckerData } as IDuplicateCheckerData;
    if (type === 'fields') {
      formDuplicateCheckerData.selectedFields = values;
    } else {
      formDuplicateCheckerData.selectedStatuses = values;
    }
    setDuplicateCheckerData(formDuplicateCheckerData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const onDuplicateCheckerChange = (value: boolean) => {
    let formDuplicateCheckerData = { ...duplicateCheckerData } as IDuplicateCheckerData;
    formDuplicateCheckerData.isDuplicateChecker = !value;
    setDuplicateCheckerData(formDuplicateCheckerData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const onConditionAndActionChange = (value: boolean, type: string) => {
    let formDuplicateCheckerData = { ...duplicateCheckerData } as IDuplicateCheckerData;
    if (type === 'or') {
      formDuplicateCheckerData.isOR = true;
      formDuplicateCheckerData.isAND = false;
    } else if (type === 'and') {
      formDuplicateCheckerData.isOR = false;
      formDuplicateCheckerData.isAND = true;
    } else if (type === 'warning') {
      formDuplicateCheckerData.isWarning = true;
      formDuplicateCheckerData.isHardStop = false;
    } else if (type === 'hardStop') {
      formDuplicateCheckerData.isWarning = false;
      formDuplicateCheckerData.isHardStop = true;
    } else if (type === 'skipNulls'){
      formDuplicateCheckerData.skipNulls = value;
    }
    setDuplicateCheckerData(formDuplicateCheckerData);
    props.hasPageChangeRef.current.hasChange = true;
  }

  const saveDuplicateChecker = (saveType: string) => {
    let formDuplicateCheckerData = { ...duplicateCheckerData } as IDuplicateCheckerData;

    let dataToSubmit = {
      statuses: formDuplicateCheckerData.selectedStatuses,
      fields: formDuplicateCheckerData.selectedFields,
      joinCondition: formDuplicateCheckerData.isOR ? 'or' : 'and',
      action: formDuplicateCheckerData.isHardStop ? 'hard_stop' : 'warning',
      skipNulls: formDuplicateCheckerData.skipNulls
    } as IDuplicateChecker;

    if (dataToSubmit && formDuplicateCheckerData.isDuplicateChecker && dataToSubmit.fields.length === 0) {
      return dispatch(saveDuplicateCheckerErrorAction('At least one form field must be selected'))
    }

    dispatch(saveDuplicateCheckerAction({
      company: company,
      formId: match.params.formId,
      saveType: saveType,
      duplicateChecker: formDuplicateCheckerData.isDuplicateChecker ? dataToSubmit : undefined
    }));
    props.hasPageChangeRef.current.hasChange = false;
  }

  const cancelDuplicateChecker = () => {
    changeRoute('/admin/business-process/form', hasPageChange || props.hasPageChangeRef.current.hasChange, { dispatch, history })
  }

  useGetDuplicateCheckerData({
    company,
    dispatch,
    formId: match.params.formId
  });

  useEffect(() => {
    if (formFieldsOptionList.length) {
      setFormFieldOption(formFieldsOptionList);
    }
  }, [formFieldsOptionList]);

  useEffect(() => {
    if (duplicateCheckerSaveType === 'publish') {
      dispatch(publishDuplicateCheckerAction({
        company: company, formId: match.params.formId
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateCheckerSaveType]);

  return <>
    <ActionMessage messages={duplicateCheckerActionMessage} />
    <div className='default-checker-main'>
      {(duplicateCheckerLoading || saveDuplicateCheckerLoading || formFieldsLoading) &&
        <Dimmer active inverted>
          <Loader active content='Loading' />
        </Dimmer>
      }
      <div className='default-checker-container'>
        <FieldsManager
          duplicateCheckerData={duplicateCheckerData}
          onDuplicateCheckerChange={onDuplicateCheckerChange}
        />
        <div className='field-list-container'>
          <div className='column-list-container'>
            {duplicateCheckerData.isDuplicateChecker &&
              <FieldsList
                formFieldOption={formFieldOption}
                onFormFieldOptionChange={onFormFieldOptionChange}
                duplicateCheckerData={duplicateCheckerData}
                onConditionAndActionChange={onConditionAndActionChange}
                statusOptionList={statusOptionList()}
              />
            }
          </div>
          <ButtonContainer
            save={() => saveDuplicateChecker('draft')}
            publish={() => saveDuplicateChecker('publish')}
            cancel={cancelDuplicateChecker}
          />
        </div>
      </div>
    </div>
  </>
}

export default DuplicateCheckerContainer;