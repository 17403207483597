import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react-bpm';
import { IOption } from '../../../../../../../../../common/interface/option';
import { IDuplicateCheckerData } from '../interface/duplicate-checker';
import DropdownPopperOption from '../../../../../../../../../common/general/dropdown-popper-option';

interface IFieldsList {
  formFieldOption: IOption[];
  duplicateCheckerData: IDuplicateCheckerData;
  onFormFieldOptionChange(value: string[], type: 'status' | 'fields'): void;
  onConditionAndActionChange(isAnd: boolean, type: string): void;
  statusOptionList: IOption[];
}

const FieldsList: React.FC<IFieldsList> = (props) => {
  return <>
    <div className='form-fields'>
      <div>
        <div style={{ marginBottom: '15px', marginTop: '5px' }}>
          <span>Form Fields</span>
        </div>
        <Dropdown
          className='fields-option'
          popperContainer={DropdownPopperOption}
          clearable
          selection
          multiple
          search
          selectOnBlur={false}
          value={props.duplicateCheckerData.selectedFields}
          options={props.formFieldOption}
          onChange={(e, target: any) => props.onFormFieldOptionChange(target.value, 'fields')}
        />
      </div>
      <div>
        <div style={{ marginBottom: '15px', marginTop: '5px' }}>
          <span>Status</span>
        </div>
        <Dropdown
          popperContainer={DropdownPopperOption}
          clearable
          selection
          multiple
          search
          selectOnBlur={false}
          value={props.duplicateCheckerData.selectedStatuses}
          options={props.statusOptionList}
          onChange={(e, target: any) => props.onFormFieldOptionChange(target.value, 'status')}
        />
      </div>
    </div>
    <div className='form-condition'>
      <span>Condition</span>
      <Form>
        <Form.Field>
          <div className="ui radio checkbox" onClick={() => {
            props.onConditionAndActionChange(props.duplicateCheckerData.isAND, 'and')
          }}>
            <input type="radio"
              className="hidden"
              name="radioGroup"
              onChange={() => { }}
              value='and'
              checked={!!props.duplicateCheckerData.isAND}
            />
            <label>AND</label>
          </div>
        </Form.Field>
        <Form.Field className='radio-or'>
          <div className="ui radio checkbox" onClick={() => {
            props.onConditionAndActionChange(props.duplicateCheckerData.isOR, 'or')
          }}>
            <input type="radio"
              className="hidden"
              name="radioGroup"
              value='or'
              checked={!!props.duplicateCheckerData.isOR}
              onChange={() => { }}
            />
            <label>OR</label>
          </div>
        </Form.Field>
      </Form>
    </div>
    <div className='form-action'>
      <span>Action</span>
      <Form>
        <Form.Field>
          <div className="ui radio checkbox" onClick={() => {
            props.onConditionAndActionChange(props.duplicateCheckerData.isWarning, 'warning')
          }}>
            <input type="radio"
              className="hidden"
              name="radioGroup"
              checked={!!props.duplicateCheckerData.isWarning}
              onChange={() => { }}
            />
            <label>Warning</label>
          </div>
        </Form.Field>
        <Form.Field className='radio-hard-stop'>
          <div className="ui radio checkbox" onClick={() => {
            props.onConditionAndActionChange(props.duplicateCheckerData.isHardStop, 'hardStop')
          }}>
            <input type="radio"
              className="hidden"
              name="radioGroup"
              checked={!!props.duplicateCheckerData.isHardStop}
              onChange={() => { }}
            />
            <label>Hard Stop</label>
          </div>
        </Form.Field>
      </Form>
    </div>
    <div className='validate-empty-value'>
        <div className='switch-field-container'>
          <span className='switch-label'>Validate empty values</span>
          <div className="ui fitted toggle checkbox"
            onClick={() => props.onConditionAndActionChange(!props.duplicateCheckerData.skipNulls, 'skipNulls')}>
            <input className="hidden" type="checkbox"
              onChange={() => { }}
              checked={!props.duplicateCheckerData.skipNulls}
            />
            <label></label>
          </div>
        </div>
    </div>
  </>
}

export default FieldsList;