import { useEffect, useState } from 'react';
import { IDuplicateChecker, IDuplicateCheckerData } from '../interface/duplicate-checker';

interface IProps {
  duplicateChecker: IDuplicateChecker;
}

export const useSetDuplicateCheckerState = (props: IProps) => {
  const { duplicateChecker } = { ...props };

  const [duplicateCheckerData, setDuplicateCheckerData] = useState({} as IDuplicateCheckerData);

  useEffect(() => {
    let formDuplicateCheckerData = {} as IDuplicateCheckerData;
    if (duplicateChecker && Object.keys(duplicateChecker).length) {
      formDuplicateCheckerData = {
        selectedStatuses: duplicateChecker.statuses,
        selectedFields: duplicateChecker.fields,
        isDuplicateChecker: true,
        isAND: duplicateChecker.joinCondition === 'and',
        isOR: duplicateChecker.joinCondition === 'or',
        isWarning: duplicateChecker.action === 'warning',
        isHardStop: duplicateChecker.action === 'hard_stop',
        skipNulls: duplicateChecker.skipNulls ?? false
      }

    } else {
      formDuplicateCheckerData = {
        selectedStatuses: [],
        selectedFields: [],
        isDuplicateChecker: false,
        isAND: true,
        isOR: false,
        isWarning: true,
        isHardStop: false,
        skipNulls: true,
      }
    }
    setDuplicateCheckerData(formDuplicateCheckerData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(duplicateChecker)]);

  return { ...props, duplicateCheckerData, setDuplicateCheckerData };
}